import { FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa";

const HomeScene = () => {
  return (
    <div className="container lg:w-12/12 mx-auto px-auto dark:text-gray-400 bg-white dark:bg-gray-800 p-8">
      <div className="md:flex md:justify-center">
        <div className="">
          <h1 className="text-6xl">
            <b>Zubin Paul</b>
          </h1>
          <h4 className="text-xl">Nagpur, Maharashtra</h4>
        </div>
        <div className="text-primary dark:text-primary-dark flex mt-4 md:m-0 md:p-4">
          <SocialButton
            href="mailto:zubinpaul@outlook.com"
            Icon={FaEnvelope}
            text="Mail"
          />
          <SocialButton
            href="https://linkedin.com/in/zubin-paul"
            Icon={FaLinkedin}
            text="LinkedIn"
          />
          <SocialButton
            href="https://github.com/zplusp"
            Icon={FaGithub}
            text="GitHub"
          />
        </div>
      </div>
      <div className="lg:flex lg:w-10/12 mx-auto">
        <div className="lg:w-8/12">
          <div className="mt-8">
            <div>
              <h1 className="text-2xl text-primary dark:text-primary-dark">
                <b>Work Experience</b>
              </h1>
              <h5 className="text-lg mt-2">
                <b>Software Engineer - II</b> at Zepto (KiranaKart Technologies
                Private Limited)
              </h5>
              <h5 className="text-md text-gray-500">
                Feb 2022 - Present; React Native
              </h5>
              <h5 className="text-md text-gray-500 mt-4">Sep 2022 - Present</h5>
              <ul className="px-8">
                <li className="list-disc">
                  Led the development of the In-House Zepto Delivery Partner App
                  built on <b>React Native</b>. This was built to remove the
                  reliance on third party systems for both the backend as well
                  as the mobile app.
                </li>
                <li className="list-disc">
                  <b>Increased Zepton NPS by 10</b> by owning the development of
                  the Earnings and Payouts module which gives the partners
                  visibility to thier thier daily/weekly/monthly earnings from
                  the orders and other incentives, on the app.
                </li>
                <li className="list-disc">
                  Led the develpoment of <b>Self Sign-Up</b> lead generation
                  module, which enabled potential delivery partners to download
                  the app, sign up and provide thier details to show interest in
                  joining our platform. They can track thier application status
                  as well as raise concerns throught the app itself.
                </li>
                <li className="list-disc">
                  Worked on the Home Page, which gives the summary of
                  all the modules in the app, such as online status, shift
                  details, daily milestones etc.
                </li>
              </ul>
              <h5 className="text-md text-gray-500 mt-2">
                Feb 2022 - Aug 2022
              </h5>
              <ul className="px-8">
                <li className="list-disc">
                  Worked primarily on the Zepto Consumer app built on{" "}
                  <b>React Native</b>.
                </li>
                <li className="list-disc">
                  <b>Increased revenue by 10%</b> by implementing{" "}
                  <b>Impressions Events</b>
                  (for view,click,add to cart etc.) across the app and help
                  business teams make informed decisions about the usage of the
                  app.
                </li>
                <li className="list-disc">
                  <b>Increased user signups by 5%</b> and decreased the login
                  failure rates by 40% by integrating Truecaller SDK to enable
                  seamless OTP-less authentication
                </li>
                <li className="list-disc">
                  Helped in increasing data driven decision making for feature
                  rollouts by enhancing the architecture of the app to support{" "}
                  <b>A/B testing</b>.
                </li>
              </ul>
              <h5 className="text-lg mt-8">
                <b>Software Development Engineer - II</b> at Fisdom (Finwizard
                Technology Private Limited)
              </h5>
              <h5 className="text-md text-gray-500">
                Sep 2021 - Jan 2022; React, React Native
              </h5>
              <ul className="px-8">
                <li className="list-disc">
                  Worked on migrating legacy admin web app on AngularJs to{" "}
                  <b>React</b>.
                </li>
                <li className="list-disc">
                  Instrumental in architecting the new React app, including
                  modular design, React Hooks, implementing <b>Redux</b> logic
                  and integrating <b>Storybook UI</b> Tool.
                </li>
              </ul>
              <h5 className="text-lg mt-8">
                <b>Senior Software Engineer</b> at OlaGate (Grhapa Services and
                Solutions)
              </h5>
              <h5 className="text-md text-gray-500">
                Jan 2018 - Aug 2021; Android, React, React Native
              </h5>
              <p className="my-2">
                Designed and Developed the web and mobile apps of multiple of
                our flagship products. Founding Member of our startup, have
                experience in building applications serving &gt; 50k users
                around India.
              </p>
              <ul className="px-8">
                <li className="list-disc">
                  Responsibilities include designing and prototyping User
                  Interface for the <b>Progressive Web Application (PWA)</b>.
                </li>
                <li className="list-disc">
                  Writing efficient code to reduce the final bundle size along
                  with code splitting.
                </li>
                <li className="list-disc">
                  Designed the architecture for the application following best
                  practices.
                </li>
                <li className="list-disc">
                  Implemented Server-Side Rendering to reduce initial load
                  times, time to interactive and first meaningful paint.
                </li>
                <li className="list-disc">
                  Developed web applications with accessibility and
                  cross-browser compatibility in mind.
                </li>
                <li className="list-disc">
                  Experienced in developing native mobile apps in <b>Koltin</b>{" "}
                  and <b>React Native</b>.
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8">
            <div>
              <h1 className="text-2xl text-primary dark:text-primary-dark">
                <b>Projects/Internship Experience</b>
              </h1>
              <h5 className="text-lg mt-2">RTM Nagpur University</h5>
              <h5 className="text-md text-gray-500">2017; Android</h5>
              <p className="my-2">
                Project under Rashtriya Uchchatar Shiksha Abhiyan (RUSA),
                Ministry of HRD, Govt. of India. The goal was to digitize
                communication within the university campus as well as offices
                located elsewhere.
              </p>
              <ul className="px-8">
                <li className="list-disc">
                  Responsible for scaling an existing prototype application.
                </li>
                <li className="list-disc">
                  Responsible for designing, developing and testing the Android
                  application.
                </li>
                <li className="list-disc">
                  Worked with various stakeholders such as less tech-savvy
                  teachers, officers and students to come with a user friendly
                  and easily accessible app.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="lg:w-4/12">
          <div className="mt-8">
            <div>
              <h1 className="text-2xl text-primary dark:text-primary-dark">
                <b>Education</b>
              </h1>
              <h5 className="text-lg mt-2">
                <b>B.E</b> in <b>Computer Science and Engineering</b>
              </h5>
              <h5 className="text-lg">
                Shri Ramdeobaba College of Engineering and Management
              </h5>
              <h5 className="text-md text-gray-500">2014-2018</h5>
            </div>
          </div>
          <div className="my-8">
            <div>
              <h1 className="text-2xl text-primary dark:text-primary-dark">
                <b>Skills</b>
              </h1>
              <ul className="px-8 mt-2">
                <li className="list-disc">JavaScript/TypeScript.</li>
                <li className="list-disc">React Native, ReactJs</li>
                <li className="list-disc">Kotlin, Android</li>
              </ul>
            </div>
          </div>
          <div className="mt-8">
            <div>
              <h1 className="text-2xl text-primary dark:text-primary-dark">
                <b>Additional Experience and Awards</b>
              </h1>
              <ul className="px-8 mt-2">
                <li className="list-disc">
                  Qualified for and participated in{" "}
                  <b>ACM ICPC Kolkata-Kanpur Regionals 2017</b>.
                </li>
                <li className="list-disc">
                  Awarded <b>Incubation Prize</b>, Smart City App-a-thon 2016,
                  to our group of 4, out of 60 projects.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SocialButton = (props) => {
  const { href, Icon, text } = props;
  return (
    <a href={href} className="mr-2" target="_blank" rel="noopener noreferrer">
      <button className="border-2 py-1 px-4 rounded flex w-full border-primary dark:border-primary-dark items-center">
        <Icon className="mr-2" />
        {text}
      </button>
    </a>
  );
};

export default HomeScene;
