import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useLoremIpsum from "./useLoremIpsum";
import { getTitle } from "./utils";

const SkillScene = () => {
  const location = useLocation();
  const [data, loading, error, fetchData] = useLoremIpsum();

  useEffect(() => {
    if (!data && !loading && !error) {
      fetchData();
    }
  }, [fetchData, data, loading, error]);

  return (
    <div className="container lg:w-8/12 mx-auto dark:text-gray-200 bg-white dark:bg-gray-800 p-8">
      <h1 className="text-6xl">
        <b>{getTitle(location?.pathname)}</b>
      </h1>
      {loading ? (
        <SkillLoading />
      ) : error ? (
        <SkillError onClick={fetchData} error={error} />
      ) : (
        data && (
          <>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[0] || ""}
            </p>
            <h3 className="text-4xl mt-8">
              <b>{"Details"}</b>
            </h3>
            <p className="pt-2 text-gray-600 dark:text-gray-400">
              {data[1] || ""}
            </p>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[2] || ""}
            </p>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[3] || ""}
            </p>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[4] || ""}
            </p>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[5] || ""}
            </p>
            <h3 className="text-4xl mt-8">
              <b>{"More Info"}</b>
            </h3>
            <p className="pt-2 text-gray-600 dark:text-gray-400">
              {data[6] || ""}
            </p>
            <p className="pt-6 text-gray-600 dark:text-gray-400">
              {data[7] || ""}
            </p>
          </>
        )
      )}
    </div>
  );
};

const SkillLoading = () => (
  <>
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
    <div className="h-6 w-32 bg-gray-200 dark:bg-gray-600 mt-8 rounded" />
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
    <div className="h-12 w-100 bg-gray-200 dark:bg-gray-600 mt-6 rounded" />
  </>
);

const SkillError = ({ onClick, error }) => (
  <div className="border mt-12 p-12 rounded text-center">
    <div>{typeof error === "string" ? error : "Something Went Wrong."}</div>
    <button onClick={onClick} className="mt-2 underline">
      <b>Try Again</b>
    </button>
  </div>
);

export default SkillScene;
