export const getTitle = (path) => {
    switch (path) {
        case "/react":
            return "ReactJS";
        case "/react-native":
            return "React Native";
        case "/android":
            return "Android";
        default:
            return "Some Skill";
    }
}