import Helmet from "react-helmet";
import HomeScene from "../../scenes/home";

import NavFooterHOC from "../../components/nav-footer-hoc";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Zubin Paul - Software Engineer</title>
      </Helmet>
      <HomeScene />
    </>
  );
};

export default NavFooterHOC()(HomePage);
