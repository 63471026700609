import { createContext, useCallback, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import HomePage from "./pages/home";
import ReactPage from './pages/react';
import ReactNativePage from './pages/react-native';
import AndroidPage from './pages/android';

export const DarkModeContext = createContext(); 

const getIsDarkMode = () => {
  if(localStorage?.getItem('dark')) {
    return JSON.parse(localStorage.getItem('dark'))
  }

  return false;
}

const App = () => {
  const [darkMode, setDarkMode] = useState(getIsDarkMode);

  const toggleDarkMode = useCallback(() => {
    localStorage.setItem('dark', !darkMode);
    setDarkMode(!darkMode);
  }, [darkMode]);


  return (
    <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <div className={darkMode ? "dark" : ""}>
        <Router>
            <Switch>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
        </Router>
      </div>
    </DarkModeContext.Provider>
  );
}

export default App;