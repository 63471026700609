import Helmet from "react-helmet";
import SkillScene from "../../scenes/skill-scene";

import NavFooterHOC from "../../components/nav-footer-hoc";

const ReactNativePage = () => {
  return (
    <>
      <Helmet>
        <title>React Native | Fisdom Challenge</title>
      </Helmet>
      <SkillScene />
    </>
  );
};

export default NavFooterHOC()(ReactNativePage);
