import { useState } from 'react';
import { create } from 'apisauce';

const api = create({
    baseURL: 'https://baconipsum.com/api',
})

const useLoremIpsum = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        setError(null);


        const response = await api.get("/?type=meat-and-filler&paras=8&start-with-lorem=1");

        if (response.ok) {
            setData(response.data || []);
        } else {
            setError(response.problem);
        }


        setLoading(false);
    };

    return [data, loading, error, fetchData];
};

export default useLoremIpsum;