import NavBar from "../nav-bar";

const NavFooterHOC = () => (WrappedComponent) => {
  const Wapper = () => (
    <div className="min-h-screen bg-white dark:bg-gray-800 flex flex-col">
      <NavBar />
      <div className={"flex-1"}>{<WrappedComponent />}</div>
    </div>
  );

  return Wapper;
};

export default NavFooterHOC;
